@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=BIZ+UDPGothic:wght@400;700&display=swap');

body {
    font-family: 'BIZ UDPGothic', sans-serif !important;
    line-height: 1.71429;
    margin-top: 80px !important;
    margin-bottom: 40px !important;
    padding: 1rem;
    text-underline-offset: 0.25em;
}

.header_style {
    background-color: rgb(13, 31, 160) !important;
    filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
    padding: 0.5rem !important;
}

.header_title {
    font-size: 2rem !important;
    font-weight: bold;
}

.tt_close {
    padding: 0.5rem;
    border-radius: 4px;
    filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
    transform: translateZ(0);
    background: rgba(255, 255, 255, 0.9);
    opacity: 1;
    display: none;
    overflow: hidden;
    position: fixed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.2s linear;
    z-index: 1000;
    text-align: center;
}

.tt_show {
    padding: 0.5rem;
    border-radius: 4px;
    filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
    transform: translateZ(0);
    background: rgba(255, 255, 255, 0.9);
    opacity: 1;
    display: block;
    overflow: hidden;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.2s linear;
    z-index: 1000;
}

.sdgs_close {
    padding: 0.5rem;
    border-radius: 4px;
    filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
    transform: translateZ(0);
    background: rgba(255, 255, 255, 0.9);
    opacity: 1;
    display: none;
    overflow: hidden;
    position: fixed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.2s linear;
    z-index: 1000;
    text-align: center;
}

.sdgs_show {
    padding: 0.5rem;
    border-radius: 4px;
    filter: drop-shadow(.1rem .1rem .1rem rgba(0, 0, 0, 0.5));
    transform: translateZ(0);
    background: rgba(255, 255, 255, 0.9);
    opacity: 1;
    display: block;
    overflow: hidden;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.2s linear;
    z-index: 1000;
}

#timetable table {
    text-align: center;
    table-layout: auto;
    width: auto;
}

#timetable table input {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.15rem;
    background-image: none;

}

#sdgs table input {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.15rem;
    background-image: none;
}

#sdgs table tr:nth-child(1) td:nth-child(1) input:checked {
    background-color: #E5243B;
    border-color: #E5243B;
}

#sdgs table tr:nth-child(1) td:nth-child(2) input:checked {
    background-color: #DDA63A;
    border-color: #DDA63A;
}

#sdgs table tr:nth-child(1) td:nth-child(3) input:checked {
    background-color: #4C9F38;
    border-color: #4C9F38;
}

#sdgs table tr:nth-child(1) td:nth-child(4) input:checked {
    background-color: #C5192D;
    border-color: #C5192D;
}

#sdgs table tr:nth-child(1) td:nth-child(5) input:checked {
    background-color: #FF3A21;
    border-color: #FF3A21;
}

#sdgs table tr:nth-child(1) td:nth-child(6) input:checked {
    background-color: #26BDE2;
    border-color: #26BDE2;
}

#sdgs table tr:nth-child(2) td:nth-child(1) input:checked {
    background-color: #FCC30B;
    border-color: #FCC30B;
}

#sdgs table tr:nth-child(2) td:nth-child(2) input:checked {
    background-color: #A21942;
    border-color: #A21942;
}

#sdgs table tr:nth-child(2) td:nth-child(3) input:checked {
    background-color: #FD6925;
    border-color: #FD6925;
}

#sdgs table tr:nth-child(2) td:nth-child(4) input:checked {
    background-color: #DD1367;
    border-color: #DD1367;
}

#sdgs table tr:nth-child(2) td:nth-child(5) input:checked {
    background-color: #FD9D24;
    border-color: #FD9D24;
}

#sdgs table tr:nth-child(2) td:nth-child(6) input:checked {
    background-color: #BF8B2E;
    border-color: #BF8B2E;
}

#sdgs table tr:nth-child(3) td:nth-child(1) input:checked {
    background-color: #3F7E44;
    border-color: #3F7E44;
}

#sdgs table tr:nth-child(3) td:nth-child(2) input:checked {
    background-color: #0A97D9;
    border-color: #0A97D9;
}

#sdgs table tr:nth-child(3) td:nth-child(3) input:checked {
    background-color: #56C02B;
    border-color: #56C02B;
}

#sdgs table tr:nth-child(3) td:nth-child(4) input:checked {
    background-color: #00689D;
    border-color: #00689D;
}

#sdgs table tr:nth-child(3) td:nth-child(5) input:checked {
    background-color: #19486A;
    border-color: #19486A;
}


#sdgs table tr:nth-child(1) td:nth-child(1) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(229, 36, 59, 0.25);
    border-color: #E5243B;
}

#sdgs table tr:nth-child(1) td:nth-child(2) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(221, 166, 58, 0.25);
    border-color: #DDA63A;
}

#sdgs table tr:nth-child(1) td:nth-child(3) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(76, 159, 56, 0.25);
    border-color: #4C9F38;
}

#sdgs table tr:nth-child(1) td:nth-child(4) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(197, 25, 45, 0.25);
    border-color: #C5192D;
}

#sdgs table tr:nth-child(1) td:nth-child(5) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 58, 33, 0.25);
    border-color: #FF3A21;
}

#sdgs table tr:nth-child(1) td:nth-child(6) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(38, 189, 226, 0.25);
    border-color: #26BDE2;
}

#sdgs table tr:nth-child(2) td:nth-child(1) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(252, 195, 11, 0.25);
    border-color: #FCC30B;
}

#sdgs table tr:nth-child(2) td:nth-child(2) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(162, 25, 66, 0.25);
    border-color: #A21942;
}

#sdgs table tr:nth-child(2) td:nth-child(3) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(253, 105, 37, 0.25);
    border-color: #FD6925;
}

#sdgs table tr:nth-child(2) td:nth-child(4) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(221, 19, 103, 0.25);
    border-color: #DD1367;
}

#sdgs table tr:nth-child(2) td:nth-child(5) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(253, 157, 36, 0.25);
    border-color: #FD9D24;
}

#sdgs table tr:nth-child(2) td:nth-child(6) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(191, 139, 46, 0.25);
    border-color: #BF8B2E;
}

#sdgs table tr:nth-child(3) td:nth-child(1) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(63, 126, 68, 0.25);
    border-color: #3F7E44;
}

#sdgs table tr:nth-child(3) td:nth-child(2) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(10, 151, 217, 0.25);
    border-color: #0A97D9;
}

#sdgs table tr:nth-child(3) td:nth-child(3) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(86, 192, 43, 0.25);
    border-color: #56C02B;
}

#sdgs table tr:nth-child(3) td:nth-child(4) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 104, 157, 0.25);
    border-color: #00689D;
}

#sdgs table tr:nth-child(3) td:nth-child(5) input:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 72, 106, 0.25);
    border-color: #19486A;
}


input[name="mark"] {
    margin: 0.15rem;
    border: 0 !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16"> <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/> </svg>') !important;
}

input[name="mark"]:checked {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16"> <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> </svg>') !important;
}

input[name="mark"]:focus {
    border-color: transparent !important;
    outline: 0 !important;
    box-shadow: none !important;
}

#accordion_menu {
    margin-bottom: 1rem;
    justify-content: center;
}

#accordion_menu table {
    table-layout: auto;
    width: auto;
    vertical-align: middle;
    white-space: nowrap;
}

#filtered_table {
    table-layout: auto;
    width: auto;
    white-space: nowrap;
    margin: 0 auto 0.5rem auto;
}

#search_button {
    margin: 0 .5rem;
}

#reset_button {
    margin: 0 .5rem;
}

.SDGs {
    display: flex;
    flex-wrap: wrap;
    width: calc(8.4rem)
}

.SDGs div[class^="sdgs"] {
    height: 1.2rem;
    width: 1.2rem;
    margin: .1rem;
    font-size: .8rem;
    color: white;
    text-align: center;
}

.sdgs1 {
    background-color: #E5243B;
}

.sdgs2 {
    background-color: #DDA63A;
}

.sdgs3 {
    background-color: #4C9F38;
}

.sdgs4 {
    background-color: #C5192D;
}

.sdgs5 {
    background-color: #FF3A21;
}

.sdgs6 {
    background-color: #26BDE2;
}

.sdgs7 {
    background-color: #FCC30B;
}

.sdgs8 {
    background-color: #A21942;
}

.sdgs9 {
    background-color: #FD6925;
}

.sdgs10 {
    background-color: #DD1367;
}

.sdgs11 {
    background-color: #FD9D24;
}

.sdgs12 {
    background-color: #BF8B2E;
}

.sdgs13 {
    background-color: #3F7E44;
}

.sdgs14 {
    background-color: #0A97D9;
}

.sdgs15 {
    background-color: #56C02B;
}

.sdgs16 {
    background-color: #00689D;
}

.sdgs17 {
    background-color: #19486A;
}

.undecided {
    color: rgb(160, 160, 160)
}

.lecture:before {
    content: url(../src/icon/pencil.svg);
    margin-right: .25rem;
    vertical-align: middle;
}

.exercise:before {
    content: url(../src/icon/people-fill.svg);
    margin-right: .25rem;
    vertical-align: middle;
}

.experiment:before {
    content: url(../src/icon/thermometer-half.svg);
    margin-right: .25rem;
    vertical-align: middle;
}

.practice:before {
    content: url(../src/icon/mortarboard.svg);
    margin-right: .25rem;
    vertical-align: middle;
}

.skill:before {
    content: url(../src/icon/wrench-adjustable.svg);
    margin-right: .25rem;
    vertical-align: middle;
}

#pagination_button {
    margin: 0.5rem !important;
}